@import './mixins';

// Here you put all global css rules.

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  overflow-x: hidden !important;
}
