body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  overflow-x: hidden !important;
}
.home-index-page {
  text-align: center;
}
.home-index-page .app {
  text-align: center;
}
.home-index-page .rekit-logo {
  -webkit-animation: app-logo-spin infinite 10s linear;
          animation: app-logo-spin infinite 10s linear;
  height: 80px;
}
.home-index-page .app-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
  overflow: hidden;
  position: relative;
}
.home-index-page .app-title {
  font-size: 1.5em;
}
.home-index-page .app-intro {
  font-size: large;
}
.home-index-page ul,
.home-index-page li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home-index-page ul {
  margin-top: 20px;
}
.home-index-page li {
  margin-top: 10px;
}
.home-index-page a {
  color: #0288d1;
  text-decoration: none;
}
.home-index-page a:hover {
  text-decoration: underline;
}
.home-index-page p.memo {
  width: 500px;
  color: #999;
  font-size: 12px;
  line-height: 150%;
  margin: auto;
}
@-webkit-keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.examples-side-panel {
  position: fixed;
  box-sizing: border-box;
  overflow: auto;
  top: 0;
  left: 0;
  margin: 0;
  padding: 40px;
  width: 260px;
  height: 100%;
  background-color: #f7f7f7;
}
.examples-side-panel ul,
.examples-side-panel li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.examples-side-panel li {
  padding: 8px;
}
.examples-side-panel a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-side-panel a:hover {
  color: #f90;
}
.examples-side-panel .memo {
  font-size: 13px;
  margin-top: 40px;
  line-height: 150%;
  color: #aaa;
}
.examples-side-panel .memo:before {
  content: ' ';
  display: block;
  height: 2px;
  width: 60px;
  margin-bottom: 10px;
  background-color: #ddd;
}
.examples-welcome-page {
  line-height: 160%;
  position: relative;
  color: #555;
}
.examples-welcome-page a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-welcome-page a:hover {
  color: #f90;
}
.examples-welcome-page .app-logo {
  position: absolute;
  top: -14px;
  left: 0px;
  width: 50px;
}
.examples-welcome-page h1 {
  padding-left: 60px;
  margin-bottom: 40px;
  font-size: 28px;
}
.examples-welcome-page h3 {
  margin-top: 20px;
}
.examples-welcome-page code {
  font-size: 14px;
}
.examples-counter-page {
  color: #555;
}
.examples-counter-page h1 {
  margin-top: 0px;
  font-size: 28px;
}
.examples-counter-page span {
  padding: 0 10px;
  display: inline-block;
  min-width: 30px;
  text-align: center;
}
.examples-counter-page button.btn-reset {
  margin-left: 15px;
}
.examples-reddit-list-page {
  color: #555;
}
.examples-reddit-list-page h1 {
  margin-top: 0px;
  font-size: 28px;
}
.examples-reddit-list-page a {
  color: #2db7f5;
  text-decoration: none;
}
.examples-reddit-list-page a:hover {
  color: #f90;
}
.examples-reddit-list-page .fetch-list-error {
  display: block;
  margin-top: 20px;
  color: red;
}
.examples-reddit-list-page .no-items-tip {
  margin-top: 15px;
}
.examples-layout .examples-page-container {
  padding: 40px 30px 0 300px;
  min-width: 400px;
  max-width: 700px;
}

